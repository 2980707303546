<template>
  <div class="form-group">
    <label>{{ $t('name') }}</label>
    <input
      v-model="flowArea.name"
      :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].Name') }]"
      type="text"
    >
    <span
      v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].Name')"
      class="badge badge-danger"
    >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].Name') }}</span>
    <br>
    <label>Static Camera Zoom</label>
    <input
      v-model="flowArea.staticCameraZoom"
      placeholder="only set this value if its a static Camera"
      :class="['form-control', { 'is-invalid': this.$validator.errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].StaticCameraZoom') }]"
      type="number"
    >
    <span
      v-show="errors.has('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].StaticCameraZoom')"
      class="badge badge-danger"
    >{{ errors.first('VideoRecorderConfigs[0].LaneConfig.FlowAreas[0].StaticCameraZoom') }}</span>
  </div>
</template>

<script>
export default {
  name: 'FlowEditorCommon',
  props: {
    flowArea: {
      type: Object,
      required: true
    },
  },
}
</script>